<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Route Planning</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small color="primary" text outlined class="mr-5" right @click="getRoutes">
          <v-icon>mdi-refresh-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- start of toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <div class="pa-2">
          <input type="file" @change="onFileChange" />
          <v-btn small dark color="success" @click="uploadFile">Upload </v-btn>
          <div v-if="message">{{ message }}</div>
        </div>
        <v-btn class="mr-1" to="/routes_planning" outlined color="toolbarIcon" text>
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          flat
          outlined
          solo
          dense
          label="search"
          style="width: 150px"
        ></v-text-field>
      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="routes"
              :search="search"
              :loading="loader"
            >
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:item.Name="{ item }">{{ item.Name }} </template>
              <template v-slot:item.calls="{ item }">{{ item.calls.length }}</template>
              <template v-slot:item.bpartners="{ item }">
                <v-btn color="blue" text :to="`/routes-outlets/${item.id}`">{{
                  item.outlets.length
                }}</v-btn>
              </template>
              <template v-slot:item.route_assignments="{ item }">
                <v-btn color="blue" text :to="`routes-assignment/${item.id}`">{{
                  item.route_assignments.length
                }}</v-btn>
              </template>
              <template v-slot:item.id="{ item }">
                <v-btn color="primary" text :to="`/routes_planning/${item.id}`"
                  ><v-icon color="black">mdi-pencil</v-icon></v-btn
                >
              </template>

              <template v-slot:item.Active="{ item }">
                <v-btn class="btnLink" color="accent" text>{{
                  item.Active == 1 ? "Active" : "In Active"
                }}</v-btn>
              </template>
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      message: "",
      search: null,
      loader: false,
      headers: [
        { text: "No.", value: "index" },
        { text: "Route Name", value: "Name" },
        { text: "Region", value: "territory.descript" },
        { text: "Outlets", value: "bpartners" },
        { text: "Route Assignments", value: "route_assignments" },
        { text: "Visits", value: "calls" },
        { text: "Status", value: "Active" },
        { text: "Created On", value: "created_at" },
        { text: "Actions", value: "id", sortable: false },
      ],
      routes: [],
    };
  },
  methods: {
    getRoutes() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/routes_planning`)
        .then((res) => {
          self.loader = false;
          if (res.ResultCode == 1200) {
            self.routes = res.ResponseData;
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async uploadFile() {
      if (!this.file) {
        this.message = "Please select a file";
        return;
      }

      let formData = new FormData();
      formData.append("file", this.file);
      this.loading = true;

      try {
        let url = "routes_import";
        let response = await this.$store.dispatch("post", {
          url,
          data: formData,
        });
        console.log(response.ResponseData);
        this.loading = false;
        this.$refs.snackbar.show(response.ResponseData, "success");
      } catch (err) {
        this.$refs.snackbar.show(err, "red");
        this.loading = false;
      }
    },
  },
  created() {
    this.getRoutes();
  },
};
</script>

<style scoped></style>
